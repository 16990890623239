import React from 'react';
import styles from './AddProductCard.module.scss';
import {Box, Link} from "@mui/material";
import {HOST_URL} from "../../constants";

const AddProductCard = () => {
	return (
		<Link className={styles.button} href={HOST_URL + "/admin/products/add"} target="_top">
			<Box className={styles.content}>
				<i className={styles.icon}>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.80141 6.31857L7.82354 1.78158L7.82354 0.852047C7.82354 0.719257 7.71289 0.608598 7.55796 0.586467L6.58417 0.586467C6.42925 0.608598 6.31859 0.719256 6.31859 0.852046L6.34072 6.31857H0.852069C0.719279 6.31857 0.608621 6.42923 0.586489 6.58415V7.55794C0.608621 7.71286 0.719279 7.82352 0.852069 7.82352L6.31859 7.80139L6.31859 13.29C6.31859 13.4228 6.42925 13.5335 6.58417 13.5556L7.55796 13.5556C7.71289 13.5335 7.82354 13.4228 7.82354 13.29L7.82354 7.80139L12.3605 7.82352H13.2901C13.4229 7.82352 13.5335 7.71286 13.5556 7.55794V6.58415C13.5335 6.42923 13.4229 6.31857 13.2901 6.31857L7.80141 6.31857Z" fill="black"/></svg>
				</i>
				<Box className={styles.title}>Add New Product</Box>
			</Box>
		</Link>
	)
}

export default AddProductCard;
