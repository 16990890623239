/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {Box, Container} from '@mui/material'

function Layout({children}) {
	return (
		<Box m={'32px'} css={css`font-family: 'Source Sans Pro', sans-serif`}>
			{children}
		</Box>
	)
}

export default Layout
