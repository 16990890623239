/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {useEffect, useRef, useState} from 'react'
import qs from 'qs'
import packageInfo from '../package.json'

import '@fontsource/source-sans-pro/300.css'
import '@fontsource/source-sans-pro/400.css'
import '@fontsource/source-sans-pro/600.css'
import '@fontsource/source-sans-pro/700.css'

import axios from 'axios'
import {
	Alert, Backdrop, Box, Button, CircularProgress, LinearProgress, Link, Modal, Stack,
} from '@mui/material'

import Layout from './components/Layout'
import PanelAppName, {checkForAppNameErrors} from './components/panels/PanelAppName'
import PanelAppDetails from './components/panels/PanelAppDetails'
import {API_URL, HOST_URL, MIN_PRODUCTS_COUNT} from './constants'
import PanelProducts from './components/panels/PanelProducts'
import PanelAppManagement from './components/panels/PanelAppManagement'
import PanelAppDesign from "./components/panels/PanelAppDesign";
import * as htmlToImage from 'html-to-image'
import {dataURLtoFile} from './utils'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const AppContext = React.createContext()

function App() {
	const panelAppNameRef = useRef()
	const panelAppDetailsRef = useRef()
	const panelProductsRef = useRef()
	const panelAppDesignRef = useRef()
	const panelAppManagementRef = useRef()
	const iconRef = useRef()
	const launchScreenRef = useRef()
	const [isSaving, setIsSaving] = useState(false)

	const [errors, setErrors] = useState([])
	const [app, setApp] = useState({
		shopname: '',
		name: '',
		alternateName1: '',
		alternateName2: '',
		alternateName3: '',
		designAcronym: 'ABC',
		url: '',
		adult: false,
		products: [],
		color1: '#ffffff',
		color2: '#000000',
		manage: false,
		autoGenerate: true,
		appleAccountName: '',
		dunsNumber: '',
	})
	const useAppState = (property, clearErrors = false) => [
		app[property],
		value => setApp(state => {
			if (clearErrors) {
				setErrors([])
			}
			return {...state, [property]: value}
		})
	]
	const [completed, setCompleted] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [authToken, setAuthToken] = useState(null)
	const [authenticationFailure, setAuthenticationFailure] = useState(false)
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [isProductsLoading, setIsProductsLoading] = useState(true)

	const [products, setProducts] = useState([])
	const [selectedProducts, setSelectedProducts] = useState([])
	const [appIconAdded, setAppIconAdded] = useState(false)
	const [launchScreenAdded, setLaunchScreenAdded] = useState(false)

	// states from api
	const [apiVersion, setApiVersion] = useState()

	const generateDataURL = async ({ref}) => {
		let data
		let startTime = performance.now()
		await new Promise(resolve => setTimeout(resolve, 1))
		await htmlToImage.toPng(ref.current, {pixelRatio:1})
			.then(dataURL=>data=dataURL)
			.catch(err=>{
				console.log(err)
			})
		console.log(`generated image (in ${performance.now() - startTime}ms)`)
		return data
	}

	const saveImage = async ({dataURL, type}) => {
		let formData = new FormData()
		const oat = (new URLSearchParams(window.location.search)).get('oat')
		formData.append('oat', oat)
		formData.append('file', dataURLtoFile(dataURL, `${type}.png`))
		formData.append('type', type)
		await axios.post(`${API_URL}/upload-image`, formData, {withCredentials: true})
			.then(({ data }) => {
				console.log(data)
			})
			.catch(err=> {
				console.error(err)
			})
			.finally(() => console.log(`uploaded ${type}.png`))
	}

	const fetchAuth = async () => {
		const {oat} = qs.parse(window.location.search.substr(1))
		try {
			const resp = await axios.get(`${API_URL}/auth`, {withCredentials: true, params: {oat}})
			setIsAuthenticated(true)
			setAuthToken(oat)
			setApp(prevState => ({...prevState, ...resp.data}))
		} catch (authenticationError) {
			console.error({authenticationError})
			if (!authenticationError.response) {
				setAuthenticationFailure(true)
			} else {
				window.parent.postMessage({type: 'authFailure'}, HOST_URL)
			}
		}
	}

	const fetchProducts = async ()=>{
		const {data} = await axios.get(`${API_URL}/products`, {withCredentials: true, params: {
			oat: authToken,
			shopname: app.shopname
		}})
		setProducts(data.products)
		setIsProductsLoading(false)
	}

	useEffect(()=>{
		window.parent.postMessage({
			type: 'frameHeight',
			height: document.documentElement.scrollHeight,
		}, HOST_URL)
	}, [document.documentElement.scrollHeight])

	useEffect(()=>{
		setSelectedProducts(products.filter(product => product?.published_to_mobile))
	}, [products])

	useEffect(()=>{
		(async () => {
			if (! app.shopname) return
			try {
				await fetchProducts()
			} catch (err) {
				console.error(err)
			}
		})()
	}, [app.shopname])

	useEffect(() => {
		const alertUser = e => {
			if (isAuthenticated && ! completed) {
				e.preventDefault()
				return e.returnValue = 'You will lose any changes. Are you sure?'
			}
		}
		window.addEventListener('beforeunload', alertUser)
		return () => {
			window.removeEventListener('beforeunload', alertUser)
		}
	}, [isAuthenticated, completed])


	useEffect(() => {
		(async () => {
			try {
				await fetchAuth()
			} catch (err) {
				console.error(err)
				setProducts([])
			} finally {
				setIsLoading(false)
			}
		})()
	}, [])

	let scrollTimeout
	const scrollTo = (ref) => {
		if (scrollTimeout) return
		scrollTimeout = setTimeout(() => {
			ref.current.scrollIntoView({behavior:'smooth'})
		}, 100)
	}

	const validate = () => {
		console.log('validate')

		const validationErrors = []
		if (app.name.trim() === '') {
			validationErrors.push({key: 'name', error: 'This field is required'})
			scrollTo(panelAppNameRef)
		}
		const appNameErrors = [
			...checkForAppNameErrors(app.name),
			...checkForAppNameErrors(app.alternateName1),
			...checkForAppNameErrors(app.alternateName2),
			...checkForAppNameErrors(app.alternateName3),
		]
		if (appNameErrors && appNameErrors.length > 0) {
			validationErrors.push(...appNameErrors)
			scrollTo(panelAppNameRef)
		}
		if (!app.url || app.url.trim() === '') {
			validationErrors.push({key: 'url', error: 'This field is required'})
			scrollTo(panelAppDetailsRef)
		}

		if (selectedProducts.length < MIN_PRODUCTS_COUNT) {
			validationErrors.push({key: 'productIdsToPublish', error: 'You must select at least nine products to request an app'})
			scrollTo(panelProductsRef)
		}
		if (!app.manage && (!app.appleAccountName || app.appleAccountName.trim() === '')) {
			validationErrors.push({key: 'appleAccountName', error: 'This field is required'})
			scrollTo(panelAppManagementRef)
		}
		if (!app.manage && (!app.dunsNumber || app.dunsNumber.trim() === '')) {
			validationErrors.push({key: 'dunsNumber', error: 'This field is required'})
			scrollTo(panelAppManagementRef)
		}
		if (!app.autoGenerate && (!appIconAdded || !launchScreenAdded)) {
			console.log('validation: ', appIconAdded, launchScreenAdded)
			validationErrors.push({key: 'imagesNotUploaded', error: 'This field is required'})
			scrollTo(panelAppDesignRef)
		}
		if (validationErrors.length > 0) {
			setErrors(validationErrors)
			return false
		}
		setErrors([])
		return true
	}

	const publishProducts = async (productIds) => {
		const publishProductsPayload = {
			oat: authToken,
			productIds
		}

		await axios.post(`${API_URL}/publishProducts`, publishProductsPayload, {withCredentials: true})
	}

	const submitApp = () => {
		(async () => {
			if (!validate()) return

			try {
				setIsSaving(true)
				await publishProducts(selectedProducts.map(p => p.id))

				const formData = new FormData()
				formData.append('oat', authToken)
				formData.append('name', app.name)
				if (app.alternateName1) formData.append('alternateName1', app.alternateName1)
				if (app.alternateName2) formData.append('alternateName2', app.alternateName2)
				if (app.alternateName3) formData.append('alternateName3', app.alternateName3)
				formData.append('designAcronym', app.designAcronym)
				formData.append('url', app.url)
				formData.append('adult', app.adult)
				formData.append('manage', app.manage)
				formData.append('appleAccountName', app.appleAccountName)
				formData.append('dunsNumber', app.dunsNumber)
				formData.append('colors', [app.color1, app.color2].join(','))

				const resp = await axios.post(`${API_URL}/submit`, formData, {withCredentials: true})
				console.log(resp.data)

				if (app.autoGenerate) {
					let iconData = await generateDataURL({ref: iconRef})
					await saveImage({dataURL: iconData, type: 'app_icon'})

					let launchScreenData = await generateDataURL({ref: launchScreenRef})
					await saveImage({dataURL: launchScreenData, type: 'launch_screen'})
				}

				setCompleted(true)

			} catch (err) {
				console.error(err)
				setCompleted(false)
				if (err.response) {
					setErrors(err.response.data)
					if (err.response.data.filter(e=>e.key==='name').length > 0) {
						scrollTo(panelAppNameRef)
					} else if (err.response.data.filter(e=>e.key==='url').length > 0) {
						scrollTo(panelAppDetailsRef)
					} else if (
						(err.response.data.filter(e=>e.key==='appleAccountName').length > 0)
						|| (err.response.data.filter(e=>e.key==='dunsNumber').length > 0)) {
						scrollTo(panelAppManagementRef)
					}
				}
			} finally {
				setIsSaving(false)
			}
		})()
	}

	useEffect(() => {
		(async () => {
			try {
				const resp = await axios.get(`${API_URL}/version`, {withCredentials: true})
				setApiVersion(resp.data.version)
			} catch (err) {
				console.error(err)
			}
		})()
	}, [])

	if (isLoading) return <Layout><LinearProgress /></Layout>
	if (authenticationFailure) return <Layout><Alert severity={'error'}>Failed to check authorization</Alert></Layout>
	if (!authenticationFailure && !isAuthenticated) return <Layout><Alert severity={'error'}>Unauthorized</Alert></Layout>

	return (
		<Layout>
			<Backdrop open={isSaving} sx={{zIndex:99999}}>
				<CircularProgress color={'inherit'} sx={{color:'white'}} />
			</Backdrop>
			<Box css={css`font-weight: normal; font-size: 36px; margin-bottom: 32px;`}>Mobile App Setup</Box>
			<Stack spacing={2}>
				<AppContext.Provider value={{authToken, app, setApp, useAppState, errors, setErrors, products, setProducts, selectedProducts, setSelectedProducts }}>
					<PanelAppName ref={panelAppNameRef} />
					<PanelAppDetails ref={panelAppDetailsRef} />
					<PanelProducts ref={panelProductsRef} isLoading={isProductsLoading} minItemsCount={MIN_PRODUCTS_COUNT}/>
					<PanelAppDesign
						iconRef={iconRef}
						launchScreenRef={launchScreenRef}
						ref={panelAppDesignRef}
						onSuccessLaunchScreen={() => { setLaunchScreenAdded(true) }}
						onSuccessAppImage={() => { setAppIconAdded(true) }}
						onErrorLaunchScreen={() => { setLaunchScreenAdded(false) }}
						onErrorAppImage={() => { setAppIconAdded(false) }}
					/>
					<PanelAppManagement ref={panelAppManagementRef} />
				</AppContext.Provider>
			</Stack>

			<Box mt={2} display={'flex'} flexDirection={'row-reverse'}>
				<Button disabled={isSaving || !selectedProducts || selectedProducts.length < MIN_PRODUCTS_COUNT } variant={'contained'} size={'large'} onClick={submitApp}>Submit App</Button>
			</Box>

			<Box mt={2} display={'block'} textAlign={'right'} component={'span'}><small>api {apiVersion} | ui {packageInfo.version}</small></Box>

			<Modal open={completed} css={css`display:flex; justify-content:center; align-items:center`}>
				<Box css={css`width: 580px;
							  height: 385px;
							  background: #FFFFFF;
							  box-shadow: 0 11px 15px rgba(0, 0, 0, 0.2);
							  border-radius: 4px;
							  &:focus-visible {outline: none;}`}>
					<Box display={'flex'}  flexDirection={'column'} justifyContent={'center'} alignItems={'center'} padding={4}>
						<FontAwesomeIcon css={css`width: 44px;
												  height: 44px;
												  background: rgba(47, 223, 117, 0.15);
												  border-radius: 100px;`}
										 icon={faCheckCircle}
										 color={'green'} />

						<Box css={css`font-family: 'Source Sans Pro', sans-serif;font-weight: 400;font-size: 24px;line-height: 30px;padding:16px;`}>
							You're done!
						</Box>
						<Box css={css`padding: 32px; padding-top:0`}>
							<Box css={css`font-family: 'Source Sans Pro'; font-style: normal; font-weight: 400; font-size: 20px; line-height: 25px;`}>What’s next?</Box>

							<Box mt={2} css={css`font-family: 'Source Sans Pro'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 135%;`}>We’ll submit your app to Apple so they can approve it to be listed to the app store.</Box>

							<Box mt={2} css={css`font-family: 'Source Sans Pro'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 135%;`}>
								You can track our progress from the Mobile App Status screen.  If you have questions, please <Link target="_top" href="https://try.commentsold.com/get-started/contact/" css={css`cursor: pointer;text-decoration:none`}>contact customer support</Link>.
							</Box>
						</Box>
						<Box>
							<Button component="a" href={HOST_URL + "/admin/mobile-setup/general"} target="_top" className='hoverGreen' css={css`padding-left: 29px; padding-right: 29px; text-transform:none; color:white; font-style: normal;font-weight: 700;font-size: 16px;background-color: #00A65A;`} size={'large'}>View Status</Button>
						</Box>
					</Box>
				</Box>
			</Modal>
		</Layout>
	)
}

export default App
