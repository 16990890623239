import React from 'react';
import styled from '@emotion/styled';

const CardGridElement = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
    grid-template-rows: repeat(auto-fit, 1fr);
    grid-auto-rows: 1fr;
    justify-items: stretch;
    align-items:stretch;
    gap:8px;
`

function CardGrid(props) {
	return (
		<CardGridElement>
            { props.children }
        </CardGridElement>
	)
}

export default CardGrid;
