// https://stackoverflow.com/a/65275233/261023
import axios from 'axios'
import {API_URL} from './constants'

export function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
	while(n--){
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type:mime });
}

export async function logEvent(authToken, eventType, params) {
	try {
		const formData = new FormData()
		formData.append('oat', authToken)
		formData.append('eventType', eventType)
		if (params) {
			Object.entries(params).forEach(([key, val])=>{
				formData.append(key, val)
			})
		}
		await axios.post(`${API_URL}/log-event`, formData, {withCredentials: true})
	} catch (err) {
		console.error(err)
	}
}
