import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle, faWarning} from '@fortawesome/pro-light-svg-icons'

const Panel = ({title, introText, panelState, children}) => {

    const [stateIcon, setStateIcon ] = useState()
    const [stateColor, setStateColor ] = useState()

    useEffect(() => {
        if( panelState === "warning"){
            setStateIcon(faWarning);
            setStateColor("#FF4961")
        }
        else if( panelState === "success"){
            setStateIcon(faCheckCircle)
            setStateColor("#2FD36F")
        }
        else{
            setStateIcon(null);
        }
    }, [panelState])

    return(
        <PanelContainer>
            <Header>
                <div>
                    <Title>{title}</Title>
                    <Intro>{introText}</Intro>
                </div>
                <StateIconContainer iconColor={stateColor}>
                    {(panelState && stateIcon) &&
                        <FontAwesomeIcon icon={stateIcon} color={stateColor} />
                    }
                </StateIconContainer>
            </Header>
            <Content>{children}</Content>
        </PanelContainer>
    )
}


Panel.propTypes = {
    title: PropTypes.string,
    introText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ]),
    panelState: PropTypes.any
};

  Panel.defaultProps = {
    title: null,
    introText: null,
    panelState: null
};


// Styles
const PanelContainer = styled.section`
    background:white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding:2rem;
`;
const Header = styled.div`
    display:flex;
    justify-content:space-between;
    gap:2rem;
    margin-bottom:2rem;

`;
const Title = styled.h2`
    margin:0;
    font-weight:400;
`;
const Intro = styled.p`
    margin-top:0.5rem;
    line-height:1.25;
    margin-bottom:0;
    &:empty{
        display:none;
    }
`;
const Content = styled.div``;

const StateIconContainer = styled.div`
    width:2rem;
    height:2rem;
    display:flex;
    justify-content:center;
    align-items:center;
    svg{
        height:2rem;
        path{
            fill: ${props => props.iconColor}
        }
    }
`;

export default Panel;
