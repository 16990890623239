/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import styles from './Panels.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {SketchPicker} from 'react-color'
import {
	Box, Checkbox, Fab,
	FormControlLabel, Grid,
	InputBase,
	Radio,
	RadioGroup,
	Stack,
} from '@mui/material'
import InputUploadAppIcon from '../InputUploadAppIcon'
import SVGIconFonts from '../../svg/SVGIconFonts'
import Chance from 'chance'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useDebouncedValue, useOutsideClick} from 'rooks'
import {AppContext} from '../../App'
import MultiStateTextInput from '../MultiStateTextInput'
import axios from 'axios'
import {API_URL} from '../../constants'
import InputUploadLaunchScreenImage from '../InputUploadLaunchScreenImage'
import SVGIcon from '../../svg/SVGIcon'
import {faArrowRotateRight} from '@fortawesome/pro-light-svg-icons/faArrowRotateRight'
import SVGLaunchScreen from '../../svg/SVGLaunchScreen'
import Panel from '../../components/Panel/Panel.js';
import {logEvent} from '../../utils'

export const PanelAppDesign = React.forwardRef((props, ref) => {
	const {
		iconRef, launchScreenRef,
		onSuccessLaunchScreen, onErrorLaunchScreen,
		onSuccessAppImage, onErrorAppImage,
	} = props

	const {app, useAppState, errors, authToken} = useContext(AppContext)

	// make randomization deterministic (somewhat)
	const [seed, setSeed] = useState(1)
	const chance = Chance.Chance(seed)

	const primaryColorRef = useRef()
	const [showPrimaryColorPicker, setShowPrimaryColorPicker] = useState(false)
	const [primaryColor, setPrimaryColor] = useAppState('color1')
	useOutsideClick(primaryColorRef, () => {
		setShowPrimaryColorPicker(false)
	})

	const secondaryColorRef = useRef()
	const [showSecondaryColorPicker, setShowSecondaryColorPicker] = useState(false)
	const [secondaryColor, setSecondaryColor] = useAppState('color2')
	useOutsideClick(secondaryColorRef, () => {
		setShowSecondaryColorPicker(false)
	})

	const [initials, setInitials] = useAppState('designAcronym')
	const [debouncedInitials, immediatelyUpdateDebouncedInitials] = useDebouncedValue(initials, 500)
	const [initialsError, setInitialsError] = useState()
	useEffect(() => {
		if (app.name) {
			setInitials(app.name.split(' ').slice(0, 3).map(word => word.slice(0, 1)).join('').toUpperCase());
		}
	}, [app.name])
	useEffect(() => {
		(async () => {
			if (debouncedInitials === '') {
				setInitialsError(null)
				return
			}
			try {
				await axios.get(`${API_URL}/name-validate`, {
					withCredentials: true,
					params: {name: debouncedInitials},
				})
				setInitialsError(null)
			} catch (err) {
				setInitialsError(err.response.data)
			}
		})()
	}, [debouncedInitials])

	// externally loaded from larger libraries, so that we only keep a few in memory at a time
	const [fontFamilies, setFontFamiles] = useState(SVGIconFonts())
	const [fontFamily, setFontFamily] = useState(chance.pickone(fontFamilies))
	useEffect(()=>{
		setFontFamily(chance.pickone(fontFamilies))
	}, [seed, fontFamilies, chance])

	const [panelState, setPanelState] = useState(null)
	const [autoGenerate, setAutoGenerate] = useAppState('autoGenerate', true)

	const uploadImageErrors = errors.filter(err=>err.key==='imagesNotUploaded')
	useEffect(()=>{
		setPanelState(null)
		if (uploadImageErrors.length > 0) {
			setPanelState("warning")
		}
	}, [errors, uploadImageErrors.length])

	return (
		<Panel
			title="Design"
			introText="Choose your app’s color scheme and icon.  If you have your own image assets, ensure that they meet the requirements and upload them here."
			panelState={panelState}
		>
			{uploadImageErrors.length > 0 && (
					<Box mt={2} ml={1}>
					<Box color={'red'}>
						<Box fontSize={'1.2rem'}>
							<FontAwesomeIcon icon={"warning"} fixedWidth style={{marginRight: '6px'}}/>
							Please fix images
						</Box>
					</Box>
					<Box css={css`font-size: 14px; color: red;`} mt={1} mb={2}>Please upload or auto generate images for your app.</Box>
				</Box>
			)}
			<div className={styles.formGroup}>
				<div className={styles.colorSelectors}>
				<Box onClick={() => {
					setShowPrimaryColorPicker(true)
				}} ref={primaryColorRef}>
					<strong>Primary color</strong>
					{showPrimaryColorPicker && <Box css={css`position: absolute;
						z-index: 1`}>
						<SketchPicker
							color={primaryColor}
							onChange={(color) => {setPrimaryColor(color.hex)}}
							onChangeComplete={color=>logEvent(authToken, 'ui-field-touched', {field: 'color-primary', primaryColor: color.hex, secondaryColor})}/>
					</Box>}
					<Box mt={1} css={css`border-radius: 4px;
						border: 1px solid silver;
						display: flex;
						box-sizing: border-box;`}>
						<Box css={css`border-right: 1px solid silver;
							padding: 4px;
							box-sizing: border-box;`}>
							<Box css={css`border-radius: 4px;
								background-color: ${primaryColor};
								width: 40px;
								height: 40px;`}/>
						</Box>
						<Box css={css`display: flex;
							padding-left: 12px;`}><InputBase value={primaryColor} size={'small'}
															css={css`font-size: 0.9rem`}/></Box>
					</Box>
				</Box>
				<Box onClick={() => {
					setShowSecondaryColorPicker(true)
				}} ref={secondaryColorRef}>
					<strong>Secondary color</strong>
					{showSecondaryColorPicker && <Box css={css`position: absolute;
						z-index: 1`}>
						<SketchPicker
							color={secondaryColor}
							onChange={(color) => {setSecondaryColor(color.hex)}}
							onChangeComplete={color=>logEvent(authToken, 'ui-field-touched', {field: 'color-secondary', secondaryColor: color.hex, primaryColor})}/>
					</Box>}
					<Box mt={1} css={css`border-radius: 4px;
						border: 1px solid silver;
						display: flex;
						box-sizing: border-box;`}>
						<Box css={css`border-right: 1px solid silver;
							padding: 4px;
							box-sizing: border-box;`}>
							<Box css={css`border-radius: 4px;
								background-color: ${secondaryColor};
								width: 40px;
								height: 40px;`}/>
						</Box>
						<Box css={css`display: flex;
							padding-left: 12px;`}><InputBase value={secondaryColor} size={'small'}
															css={css`font-size: 0.9rem`}/></Box>
					</Box>
				</Box>
				</div>
			</div>
			<div className={styles.formGroup}>
				<p className={styles.FormGroupLabel} css={css`font-weight: 800`}>Would you like to auto generate a logo for your app or upload your own?</p>
				<RadioGroup value={autoGenerate ? 'yes' : 'no'} row>

					<div className={styles.autoOrUpload}>
						<Box css={css`border: ${autoGenerate ? `2px solid black` : `1px solid silver`};
							border-radius: 4px;
							cursor: pointer`} pt={1} pb={1} pl={2} pr={2} onClick={() => setAutoGenerate(true)}>
							<FormControlLabel value={'yes'} control={<Radio/>}
												label={<Box fontWeight={'bold'}>Auto generate</Box>}/>
						</Box>
						<Box css={css`border: ${!autoGenerate ? `2px solid black` : `1px solid silver`};
							border-radius: 4px;
							cursor: pointer`} pt={1} pb={1} pl={2} pr={2} onClick={() => setAutoGenerate(false)}>
							<FormControlLabel value={'no'} control={<Radio/>}
												label={<Box fontWeight={'bold'}>Upload</Box>}/>
						</Box>
					</div>
				</RadioGroup>
			</div>

			{autoGenerate && <Box>
				<div className={styles.formGroup}>
					<div className={styles.iconRow}>
					<Box mb={2}>
						<strong>Icon Initials</strong>
						<MultiStateTextInput
							name={'initials'}
							value={initials.toUpperCase()}
							onChange={evt => setInitials(evt.target.value)}
							onClear={evt => setInitials('')}
							onBlur={evt=>{ logEvent(authToken, 'ui-field-touched', {field: 'initials', initials: evt.target.value}) }}
							helperText={'Enter up to 1-3 characters. Avoid profanity.'}
							errors={initialsError ? [`Your initials cannot be a profanity.`] : null}
							inputProps={{maxLength: 3}}
						/>
					</Box>
					<Box >
							<strong>Icon Design</strong>
							<Box mt={1} css={css`border:3px solid black; border-radius: 6px; position: relative`} p={3}>
								<Checkbox checked={true} color={'default'} css={css`position: absolute; right:0; top: 0; padding: 4px`} />
								<Stack justifyContent={'center'} alignItems={'center'}>
									<Box css={css`width:128px; height:128px; border-radius: 26px; overflow: hidden; user-select: none`} mb={1}>
										<SVGIcon
											width={128} height={128} margin={60}
											text={initials} fontFamily={fontFamily}
											colorPalette={[primaryColor, secondaryColor]} primaryColor={primaryColor} secondaryColor={secondaryColor}
											seed={seed}
										/>
									</Box>
									<Fab size={'small'} color={'default'} css={css`background-color: white;`}
									     onClick={()=>{
											 setSeed(Date.now())
										     logEvent(authToken, 'ui-field-touched', {field: 'icon-generate'})
										 }}
									>
										<FontAwesomeIcon icon={faArrowRotateRight} />
									</Fab>
								</Stack>
							</Box>
						</Box>

					</div>
					</div>
			</Box>
			}
			{!autoGenerate && <Box container spacing={2} display={'flex'} flexDirection={'row'} marginTop={'24px'}>
				<Box item xs={12} md={4} marginRight={'34px'}>
					<InputUploadLaunchScreenImage onSuccess={onSuccessLaunchScreen} onUploadError={onErrorLaunchScreen} />
				</Box>
				<Box item xs={12} md={4}>
					<InputUploadAppIcon onSuccess={onSuccessAppImage} onUploadError={onErrorAppImage} />
				</Box>
			</Box>}
			<Box sx={{width: 0, height: 0, overflow: 'hidden'}}>
				<SVGIcon
					ref={iconRef}
					width={1024} height={1024} margin={60}
					text={initials} fontFamily={fontFamily}
					colorPalette={[primaryColor, secondaryColor]} primaryColor={primaryColor} secondaryColor={secondaryColor}
					seed={seed}
				/>
				<SVGLaunchScreen
					ref={launchScreenRef}
					width={1000} height={1600} margin={60}
					text={initials} fontFamily={fontFamily}
					colorPalette={[primaryColor, secondaryColor]} primaryColor={primaryColor} secondaryColor={secondaryColor}
					seed={seed}
				/>
			</Box>
		</Panel>
	)
})

export default PanelAppDesign
