/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {Box, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup} from '@mui/material'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlusCircle, faQuestionCircle, faText} from '@fortawesome/pro-regular-svg-icons'
import MultiStateTextInput from '../MultiStateTextInput'
import React, {useContext, useEffect, useState} from 'react'
import {faCheckCircle, faWarning} from '@fortawesome/pro-light-svg-icons'
import {AppContext} from '../../App'
import Panel from '../../components/Panel/Panel.js'
import {logEvent} from '../../utils'

export const checkForAppNameErrors = (value) =>{
	if (!value || value === '') return []
	const foundErrors = []
	if (value.trim().split(' ').length < 2) {
		foundErrors.push([`Your app name must be at least 2 words.`])
	}
	return foundErrors
}

export const PanelAppName = React.forwardRef(({}, ref) => {
	const {useAppState, errors, setErrors, authToken} = useContext(AppContext)

	const [appDisplayName, setAppDisplayName] = useAppState('name')
	const [alternateName1, setAlternateName1] = useAppState('alternateName1')
	const [alternateName2, setAlternateName2] = useAppState('alternateName2')
	const [alternateName3, setAlternateName3] = useAppState('alternateName3')

	const [isProvidingAlternates, setIsProvidingAlternates] = useState(false)
	const [hasErrors, setHasErrors] = useState(false)
	const [isDirty, setIsDirty] = useState(false)
	const [panelState, setPanelState] = useState(null)

	useEffect(()=>{
		setErrors([])

		setIsDirty(
			appDisplayName !== ''
			|| alternateName1 !== ''
			|| alternateName2 !== undefined
			|| alternateName3 !== undefined
		)

		setHasErrors(
			checkErrors()
			|| checkForAppNameErrors(appDisplayName).length > 0
			|| checkForAppNameErrors(alternateName1).length > 0
			|| checkForAppNameErrors(alternateName2).length > 0
			|| checkForAppNameErrors(alternateName3).length > 0
		)
		if (! isProvidingAlternates) {
			setAlternateName1(null)
			setAlternateName2(null)
			setAlternateName3(null)
		}
	}, [appDisplayName, alternateName1, alternateName2, alternateName3, isProvidingAlternates])

	useEffect(()=>{
		if (checkErrors('name') || checkErrors('alternateName1') || checkErrors('alternateName2') || checkErrors('alternateName3')) {
			setPanelState("warning")
			return
		}

		if (!isDirty){
			setPanelState(undefined)
			return
		}

		if (isDirty && !hasErrors){
			setPanelState("success")
			return
		}

		if (hasErrors) {
			setPanelState("warning")
		}
	}, [hasErrors, isDirty, errors])

	const checkErrors = (key) => {
		if (!errors) return null
		const keyErrors = errors.filter(err=>(err.key===key))
		if (!keyErrors || keyErrors.length === 0) return null
		return keyErrors.map(err=>err.error)
	}

	return (
		<Panel
			title="App Name"
			introText="The name of your app must be unique and not already taken by another app in Apple's App Store or Google Play. By default, we set it to the name of your shop."
			panelState={panelState}

		>
			<Box fullWidth>
				<label>Preferred App Name</label>
				<MultiStateTextInput
					name={'name'}
					value={appDisplayName}
					helperText={"Enter an app name with at least 2 words. Avoid profanity and trademarks."}
					onChange={evt=>setAppDisplayName(evt.target.value)}
					onClear={evt=>setAppDisplayName('')}
					onBlur={()=>{ logEvent(authToken, 'ui-field-touched', {field: 'name', appDisplayName, isProvidingAlternates, alternateName1, alternateName2, alternateName3}) }}
					errors={checkErrors('name') ? checkErrors('name') : checkForAppNameErrors(appDisplayName)}
				/>
			</Box>

			<Box mt={3} component={FormControl} fullWidth>
				<Box mb={1}>
					<Box pr={1} component={'span'} css={css`font-weight: 800`}>
						If your app name is already taken, would you like us to modify it for you so your app launches on schedule?
					</Box>
					<FontAwesomeIcon icon={faQuestionCircle} color={'blue'} />
				</Box>
				<RadioGroup value={isProvidingAlternates ? 'no' : 'yes'} row>
					<Grid container spacing={2}>
						<Grid item xs={12} md={4}>
							<Box css={css`border: ${!isProvidingAlternates ? `2px solid black` : `1px solid silver`}; border-radius: 4px; cursor: pointer`} pt={1} pb={1} pl={2} pr={2} onClick={()=>setIsProvidingAlternates(false)}>
								<FormControlLabel value={"yes"} control={<Radio />} label={<Box fontWeight={'bold'}>Yes, modify the name for me.</Box>} />
							</Box>
						</Grid>
						<Grid item xs={12} md={4}>
							<Box css={css`border: ${isProvidingAlternates ? `2px solid black` : `1px solid silver`}; border-radius: 4px; cursor: pointer`} pt={1} pb={1} pl={2} pr={2} onClick={()=>setIsProvidingAlternates(true)}>
								<FormControlLabel value={"no"} control={<Radio />} label={<Box fontWeight={'bold'}>No, I'll provide some alternate names.</Box>} />
							</Box>
						</Grid>
					</Grid>
				</RadioGroup>
			</Box>

			{isProvidingAlternates && <Box mt={3} p={2} fullWidth css={css`background-color: #f3f5f7`}>
				<strong>Alternate App Names</strong>
				<MultiStateTextInput
					name={'alternateName1'}
					value={alternateName1}
					helperText={"Enter an app name with at least 2 words. Avoid profanity and trademarks."}
					onChange={(evt)=>{setAlternateName1(evt.target.value)}}
					onClear={evt=>setAlternateName1('')}
					onBlur={()=>{ logEvent(authToken, 'ui-field-touched', {field: 'name', appDisplayName, isProvidingAlternates, alternateName1, alternateName2, alternateName3}) }}
					errors={checkErrors('alternateName1') ? checkErrors('alternateName1') : checkForAppNameErrors(alternateName1)}
				/>

				{alternateName2 !== undefined && <MultiStateTextInput
					name={'alternateName2'}
					value={alternateName2}
					helperText={"Enter an app name with at least 2 words. Avoid profanity and trademarks."}
					onChange={evt=>setAlternateName2(evt.target.value)}
					onClear={evt=>setAlternateName2('')}
					onRemove={evt=>setAlternateName2(undefined)}
					onBlur={()=>{ logEvent(authToken, 'ui-field-touched', {field: 'name', appDisplayName, isProvidingAlternates, alternateName1, alternateName2, alternateName3}) }}
					errors={checkErrors('alternateName2') ? checkErrors('alternateName2') : checkForAppNameErrors(alternateName2)}
				/>}

				{alternateName3 !== undefined && <MultiStateTextInput
					name={'alternateName3'}
					value={alternateName3}
					helperText={"Enter an app name with at least 2 words. Avoid profanity and trademarks."}
					onChange={(evt)=>{setAlternateName3(evt.target.value)}}
					onClear={evt=>setAlternateName3('')}
					onRemove={evt=>setAlternateName3(undefined)}
					onBlur={()=>{ logEvent(authToken, 'ui-field-touched', {field: 'name', appDisplayName, isProvidingAlternates, alternateName1, alternateName2, alternateName3}) }}
					errors={checkErrors('alternateName3') ? checkErrors('alternateName3') : checkForAppNameErrors(alternateName3)}
				/>}

				{(alternateName2 === undefined || alternateName3 === undefined) && <Box
					fontSize={'18px'}
					fontWeight={'bold'}
					css={css`cursor: pointer; display: inline-block`}
					mt={2} ml={1}
					onClick={()=>{
						if (alternateName2 === undefined) setAlternateName2('')
						if (alternateName2 !== undefined && alternateName3 === undefined) setAlternateName3('')
					}}
				>
					<Box mr={1} display={'inline-block'}><FontAwesomeIcon icon={faPlusCircle} size={'xl'} /></Box>
					Add Alternate App Name
				</Box>}
			</Box>}
		</Panel>

	)
})

export default PanelAppName
