import React, {forwardRef} from 'react'
import SVGIconGraphic from './SVGIconGraphic'

const SVGIcon = forwardRef(({width, height, margin, text, colorPalette, fontFamily, seed, primaryColor, secondaryColor, onClick}, ref) => {
	const chance = require('chance').Chance(seed)
	const w = width
	const h = height
	const scale = w / 512
	width = 512
	height = 512

	const strokeColor = primaryColor
	const shadowDistance = chance.pickone([2, 4, 6, 8])

	const filter = chance.pickone([
		// stroke and shadow
		`url(#stroke-${chance.integer({min:2, max:4})}) url(#shadow-${shadowDistance})`,
		// just stroke
		`url(#stroke-${chance.integer({min:4, max:8})})`,
	])

	const bgFill = chance.pickone(['url(#gradient1)', 'url(#gradient2)','url(#gradient3)', secondaryColor])
	const textFill = chance.pickone(['url(#gradient1)', 'url(#gradient2)','url(#gradient3)', secondaryColor])

	return <div style={{width:width*scale, height:height*scale, position:'relative'}} ref={ref} onClick={onClick} >
		<svg style={{width, height, position:'absolute', left:(w-512)/2, top:(h-512)/2}} transform={`scale(${scale})`} width={512} height={512} viewBox={'0 0 512 512'} xmlns="http://www.w3.org/2000/svg">
			<defs>

				<style type="text/css">
					{`@import url('https://fonts.googleapis.com/css2?family=${fontFamily.replace(' ', '+')}');`}
				</style>
				<linearGradient id="gradient1" x1="0" x2="0" y1="0" y2="100%" gradientUnits="userSpaceOnUse"
				                gradientTransform={`rotate(0)`}>
					{colorPalette.map((cp, ci) => {
						return <stop key={`cp1-${ci}`} stopColor={cp} offset={`${(ci / colorPalette.length) * 100}%`}/>
					})}
				</linearGradient>
				<linearGradient id="gradient2" x1="0" x2="0" y1="0" y2="100%" gradientUnits="userSpaceOnUse"
				                gradientTransform={`translate(350 0) rotate(45) scale(1.5)`}>
					{colorPalette.map((cp, ci) => {
						return <stop key={`cp2-${ci}`} stopColor={cp} offset={`${(ci / colorPalette.length) * 100}%`}/>
					})}
				</linearGradient>
				<linearGradient id="gradient3" x1="0" x2="0" y1="0" y2="100%" gradientUnits="userSpaceOnUse"
				                gradientTransform={`translate(0 0) rotate(-45) scale(1.5)`}>
					{colorPalette.map((cp, ci) => {
						return <stop key={`cp3-${ci}`} stopColor={cp} offset={`${(ci / colorPalette.length) * 100}%`}/>
					})}
				</linearGradient>

				{/* shadows */}
				{[2, 4, 6, 8].map(dist=><filter key={`shadow-${dist}`} id={`shadow-${dist}`}>
					<feDropShadow key={`fe${dist}`} dx={dist} dy={dist} floodColor="black" floodOpacity="0.4"/>
				</filter>)}

				{/* strokes */}
				{[0, 1, 2, 3, 4, 5, 6, 7, 8].map(strokeSize=><filter key={`stroke-${strokeSize}`} id={`stroke-${strokeSize}`}>
					<feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius={strokeSize}/>
					<feFlood floodColor={strokeColor} floodOpacity="1" result="PINK"/>
					<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"/>
					<feMerge>
						<feMergeNode in="OUTLINE"/>
						<feMergeNode in="SourceGraphic"/>
					</feMerge>
				</filter>)}
			</defs>
			<rect x="0" y="0" width={width} height={height} style={{fill: bgFill}}/>
			<SVGIconGraphic seed={seed} primaryColor={primaryColor} secondaryColor={secondaryColor} height={height} width={width} />
			<text x={(width / 2)} y={(height / 2) + 60} style={{
				textAnchor: 'middle',
				fontSize: (width - margin * 2) - text.length * (70),
				fill: textFill,
				fontFamily,
				filter,
			}}>{text}</text>
		</svg>
	</div>
})

export default SVGIcon
