/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
	Box,
	FormControl, FormControlLabel, Grid,
	Paper, Radio,
	RadioGroup,
} from '@mui/material'
import { useDebouncedValue } from 'rooks'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faQuestionCircle, faText} from '@fortawesome/pro-regular-svg-icons'
import React, {useContext, useDeferredValue, useEffect, useMemo, useState} from 'react'
import axios from 'axios'
import {API_URL} from '../../constants'
import MultiStateTextInput from '../MultiStateTextInput'
import {AppContext} from '../../App'
import Panel from '../../components/Panel/Panel.js'
import {logEvent} from '../../utils'

const PanelAppDetails = React.forwardRef(({}, ref) => {
	const {useAppState, errors, setErrors, authToken} = useContext(AppContext)
	const [isAdultContent, setIsAdultContent] = useAppState('adult')
	const [urlError, setURLError] = useState(null)
	const [panelState, setPanelState] = useState(null)
	const [panelStateIcon, setPanelStateIcon] = useState(null)
	const [url, setURL] = useAppState('url')
	const [debouncedURL, immediatelyUpdateDebouncedURL] = useDebouncedValue(url, 500)

	useEffect(()=>{
		(async ()=>{
			setPanelState(null)
			if (url === '') return
			try {
				const resp = await axios.get(`${API_URL}/url-validate`, {withCredentials: true, params: {url}})
				setURLError(null)
				setPanelState('success')
			} catch (err) {
				console.error(err.response.data)
				setURLError("Please enter a valid URL")
				setPanelState('warning')
			}
		})()
	}, [debouncedURL])

	useEffect(()=>{
		setURLError(null)
	}, [url])

	useEffect(()=>{
		setErrors([])
	}, [url, urlError])

	useEffect(()=>{
		if (errAggregator() && errAggregator().length > 0) {
			setPanelState('warning')
		} else if (url === "") {
			setPanelState(null)
		}
	}, [errors])

	const urlErrors = errors.filter(err=>err.key==='url')
	const errAggregator = () => {
		if (urlErrors.length > 0) return urlErrors.map(({error})=>error)
		if (url === '') return []
		if (urlError) return [urlError]
		return null
	}

	return (
		<Panel
			title="General"
			panelState={panelState}
		>
			<Box fullWidth>
				<strong>Contact URL</strong>
				<MultiStateTextInput
					name={'url'}
					value={url}
					helperText={"Enter the URL of your website's contact page."}
					onChange={evt=>setURL(evt.target.value)}
					onClear={evt=>setURL('')}
					onBlur={evt=>{ logEvent(authToken, 'ui-field-touched', {field: 'url', url: evt.target.value}) }}
					errors={(errAggregator() && errAggregator().length > 0) ? ["Please enter a valid URL."] : []}
				/>
			</Box>

			<Box mt={3} component={FormControl} fullWidth>
				<Box mb={1}>
					<Box pr={1} component={'span'} css={css`font-weight: 800`}>
						Do any of the products you sell contain adult content, such as nudity, adult language, or suggestive imagery?
					</Box>
					<FontAwesomeIcon icon={faQuestionCircle} color={'blue'} />
				</Box>
				<RadioGroup value={isAdultContent ? 'yes' : 'no'} row>
					<Grid container spacing={2}>
						<Grid item xs={12} md={4}>
							<Box css={css`border: ${isAdultContent ? '2px solid black' : '1px solid silver'}; border-radius: 4px; cursor: pointer;`}
							     pt={1} pb={1} pl={2}
							     onClick={()=>{
									 setIsAdultContent(true)
								     logEvent(authToken, 'ui-field-touched', {field: 'isAdultContent', isAdultContent: true})
								 }}>
								<FormControlLabel value={"yes"} control={<Radio />} label={<Box fontWeight={'bold'}>Yes</Box>} />
							</Box>
						</Grid>
						<Grid item xs={12} md={4}>
							<Box css={css`border: ${!isAdultContent ? '2px solid black' : '1px solid silver'}; border-radius: 4px; cursor: pointer;`}
							     pt={1} pb={1} pl={2}
							     onClick={()=>{
								     setIsAdultContent(false)
								     logEvent(authToken, 'ui-field-touched', {field: 'isAdultContent', isAdultContent: false})
							     }}
							>
								<FormControlLabel value={"no"} control={<Radio />} label={<Box fontWeight={'bold'}>No</Box>} />
							</Box>
						</Grid>
					</Grid>
				</RadioGroup>
			</Box>
		</Panel>
	)
})

export default PanelAppDetails
