import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SelectCard.module.scss';
import ProductPlaceHolderImage from './Product_Placeholder_Image.png'


const SelectCard = ({ imgSrc, title, name, uid,  value, checked, constrained, disabled, disabledMessage, ...props }) => {
    const altTag = "Thumbnail image of " + props.title;
    const [xPos, setXpos] = useState(0);
    const [yPos, setYpos] = useState(0);
    const [showMessage, setShowMesage] = useState(false);
    
    const getCursorPosition = (e) => {
            setXpos(e.clientX + 'px');
            setYpos(e.clientY + 'px');
    }
    const handleClick = (e) => {
        if( disabled && disabledMessage  ){
            getCursorPosition(e)
            if(!showMessage){
                setShowMesage(true)
                setTimeout(() => {
                    setShowMesage(false)
                }, "2500")
            }
        }
        props.clickHandler(uid, e.target.checked)
    }
    const handleMouseOut = () =>{
        setShowMesage(false)
    }

	return (
		<label 
            onClick={(e) => handleClick(e)} 
            onMouseOut={() => handleMouseOut() }
            className={[styles.card, (constrained ? styles.constrained : []), (disabled ? styles.disabled : []) ].join(' ')} >
            <input 
                className={styles.input} 
                type="checkbox" 
                name={name} 
                id={uid} 
                value={value}
                defaultChecked={checked}
                disabled={disabled}
            />
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.toggle}></div>
                    <img src={imgSrc ? imgSrc : ProductPlaceHolderImage } alt={altTag} className={styles.img} />
                    <p className={styles.title}>{title}</p>
                </div>
            </div>
            { disabled && disabledMessage &&
                <div className={[styles.message, (showMessage ? styles.messageActive: [] )].join(' ') } style={{ left: xPos, top: yPos}}>
                    <p>{disabledMessage}</p>
                </div>
            }
        </label>
	)
}

export default SelectCard;

SelectCard.propTypes = {
    imgSrc: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    uid: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    constrained: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledMessage: PropTypes.string
  };
  
  SelectCard.defaultProps = {
    imgSrc: null,
    title: null,
    name: null,
    uid: null,
    value: null,
    checked: null,
    constrained: false,
    disabled:false,
    disabledMessage:null
  };