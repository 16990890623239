/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {useContext} from 'react';
import {Alert, AlertTitle, Box, CircularProgress, Paper, Typography} from '@mui/material'
import {AppContext} from '../../App'
import CardGrid from '../CardGrid/CardGrid'
import AddProductCard from '../AddProductCard/AddProductCard'
import SelectCard from "../SelectCard/SelectCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWarning} from "@fortawesome/pro-regular-svg-icons";
import Panel from '../../components/Panel/Panel.js';

const PanelProducts = React.forwardRef(({ isLoading, minItemsCount}, ref) => {
	const { products, setProducts, selectedProducts, errors, app} = useContext(AppContext)

	const handleSelectProduct = (productId, checked) => {
		if (typeof checked === typeof undefined) return

		const newProductsList = products.map(product => {
			if (product && productId === String(product.id)) {
				product.published_to_mobile = checked
			}
			return product
		})
		setProducts(newProductsList)
	}

	const productErrors = errors.filter(err=>err.key==='productIdsToPublish')

 return (
	<Panel
		title="Mobile App Inventory"
		introText={<span>Start building your Mobile App Inventory by selecting up to 30 of the following in-stock products from your shop. Once your app is published, you’ll be able to manage and add more products. <b>To meet Apple's requirements, ensure that at least 9 products are selected and that they will be in-stock for at least the next 7 days.</b></span>}
	>
		{app && app.adult && <Alert severity={'info'}>
			<AlertTitle>Important!</AlertTitle>
			Ensure that the selected products do not contain any nudity, adult language or suggestive images. Not doing
			so could result in a significant delay and/or your app being rejected by Apple and Google!
		</Alert>}

		<Box mt={4}>
			<Box display={'block'} mt={2} component={Typography} variant={'subtitle'}>
			<fieldset className='crisp-fieldset'>
				<legend className='crisp-legend'>Select Products <span css={css`font-weight:400; font-style: italic;`}>({minItemsCount} minimum)</span></legend>
				{ !isLoading && productErrors.length > 0 && (
					<Box ml={1}>
						<Box color={'red'}>
								<Box fontSize={'1.2rem'}>
									<FontAwesomeIcon icon={faWarning} fixedWidth style={{marginRight: '6px'}}/>
									Please select products
								</Box>
						</Box>
						<Box css={css`font-size: 14px; color: red;`} mt={1} mb={2}>{productErrors[0].error}.</Box>
					</Box>
				)}
				<CardGrid mt={2}>
					{isLoading ? <CircularProgress /> : null}

					{!isLoading && products && products.map((product, index) => (
						<SelectCard key={index} clickHandler={handleSelectProduct} title={product.product_name} imgSrc={product.thumb} uid={String(product.id)} value={"product_" + product.id} name="products" checked={!!parseInt(product.published_to_mobile)} disabled={!!parseInt(product.published_to_mobile)}/>
					))}
					{ !isLoading && products.length < minItemsCount
						? Array(minItemsCount - products.length).fill(1).map((i, index)=>(<AddProductCard key={index} />))
						: null}
				</CardGrid>
			</fieldset>
			</Box>
			<Box css={css`display:flex;`}>
				{!isLoading ? <Box css={css`margin-left: auto; padding-right: 16px; font-size: 16px;`}><b>{selectedProducts?.length}</b> Products Selected</Box> : null }
			</Box>
		</Box>
	</Panel>
 )
})

export default PanelProducts
