/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {Avatar, Box, Grid, InputAdornment, InputBase, Paper} from '@mui/material'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil} from '@fortawesome/pro-light-svg-icons'
import React, {useRef, useState} from 'react'
import {useOutsideClick} from 'rooks'
import {faMultiply} from '@fortawesome/pro-solid-svg-icons'
import {faWarning} from '@fortawesome/pro-regular-svg-icons'

function MultiStateTextInput({
	                             value,
	                             helperText,
	                             onChange,
	                             onBlur,
	                             onRemove,           // clicking the "X" icon at the element level
	                             onClear,            // clicking the "X" icon within the text input
	                             errors,              // error message to show
								 inputProps,
	                             debug = false, // show or hide state labels
                             }) {
	const [isEditable, setIsEditable] = useState(false)
	const hasError = errors && errors.length > 0

	const pRef = useRef()
	useOutsideClick(pRef, () => {
		setIsEditable(false)
	})

	// State 0 (non-editable, empty)
	if (!isEditable && value === '' && !hasError) {
		return (
			<Box>
				{debug && <small>State 0 (non-editable, empty)</small>}
				<Box css={css`background-color: #fff; display: flex; border: 1px solid silver; border-radius: 4px`} mt={2} p={2}
				     component={Grid} container ref={pRef}>
					<Box component={Grid} item xs={12} md={8} lg={6}>
						<Paper elevation={3}>
							<InputBase
								onFocus={() => {
									setIsEditable(true)
								}}
								css={css`
									border-width: 2px;
									border-style: solid;
									border-color: white;
									padding: 6px 10px;
									font-size: 14px;
									border-radius: 4px;
								`}
								fullWidth
							/>
						</Paper>
						<Box css={css`font-size: 14px`} mt={1}>{helperText}</Box>
					</Box>
					<Box component={Grid} item xs={12} md={8} lg={6}>
						{onRemove &&
							<Box flex justifyContent={'flex-end'} alignItems={'center'} alignContent={'center'}>
								<Avatar
									onClick={onRemove}
									css={css`
										background-color: white;
										filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
										float: right;
									  	cursor: pointer;
									`}
								>
									<FontAwesomeIcon icon={faMultiply} color={'black'}/>
								</Avatar>
							</Box>}
					</Box>
				</Box>
			</Box>
		)
	}

	// State 2 (non-editable, non-empty)
	if (!isEditable && !hasError) return (
		<Box>
			{debug && <small>State 2 (non-editable, non-empty)</small>}
			<Box css={css`background-color: #fff; display: flex; border: 1px solid silver; border-radius: 4px`} mt={2} p={2}
			     component={Grid} container ref={pRef}>
				<Box component={Grid} item xs={12} md={8} lg={6}>
					<Box p={2} onClick={() => {
						setIsEditable(true)
					}} css={css`cursor: pointer`}>
						{value} <Box display={'inline'} ml={1}><FontAwesomeIcon icon={faPencil} size={'xs'} fixedWidth/></Box>
					</Box>
				</Box>
				<Box component={Grid} item xs={12} md={8} lg={6}>
					{onRemove && <Box flex justifyContent={'flex-end'} alignItems={'center'} alignContent={'center'}>
						<Avatar
							onClick={onRemove}
							css={css`
										background-color: white;
										filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
										float: right;
									  	cursor: pointer;
									`}
						>
							<FontAwesomeIcon icon={faMultiply} color={'black'}/>
						</Avatar>
					</Box>}
				</Box>
			</Box>
		</Box>
	)

	// editable state
	return (
		<Box>
			{(debug && hasError) && <small>State 3 (editable, error)</small>}
			{(debug && !hasError) && <small>State 4 (editable, no error)</small>}
			<Box css={css`background-color: #fff; display: flex; border: 1px solid silver; border-radius: 4px`} mt={2} p={2}
			     component={Grid} container ref={pRef}>
				<Box component={Grid} item xs={12} md={8} lg={6}>
					<InputBase
						onFocus={() => {
							setIsEditable(true)
						}}
						onChange={(evt) => {
							onChange(evt)
						}}
						autoFocus
						inputProps={inputProps}
						onBlur={onBlur}
						value={value}
						css={css`
							border-width: 2px;
							border-style: solid;
							padding: 6px 10px;
							font-size: 14px;
							border-radius: 4px;
							border-color: ${hasError ? 'red' : isEditable ? 'black' : 'white'};
						`}
						fullWidth
						endAdornment={
							value && value.length > 0
								? <InputAdornment position={'end'} css={css`cursor: pointer`} onClick={onClear}>
									<FontAwesomeIcon icon={faMultiply} fixedWidth size={'lg'}
									                 color={hasError ? 'red' : null}/>
								</InputAdornment>
								: null
						}
					/>

					{/* show helper text if there is an error, or if field is currently editable */}
					{(isEditable || hasError) && <Box mt={2} ml={1}>
						<Box color={'red'} css={css`display: ${hasError ? null : 'none'}`}>
							{hasError && errors.map(err =>
								<Box fontSize={'1.2rem'} key={err}>
									<FontAwesomeIcon icon={faWarning} fixedWidth style={{marginRight: '6px'}}/>
									{err}
								</Box>,
							)}
						</Box>
						<Box css={css`font-size: 14px; color: ${hasError ? 'red' : 'inherit'}`} mt={1}>{helperText}</Box>
					</Box>}

				</Box>
				<Box component={Grid} item xs={12} md={8} lg={6}>
					{onRemove &&
						<Box pt={'5px'} flex justifyContent={'flex-end'} alignItems={'center'} alignContent={'center'}>
							<Avatar
								onClick={onRemove}
								css={css`
										background-color: white;
										filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
										float: right;
									  	cursor: pointer;
									`}
							>
								<FontAwesomeIcon icon={faMultiply} color={'black'}/>
							</Avatar>
						</Box>}
				</Box>
			</Box>
		</Box>
	)
}

export default MultiStateTextInput
