/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {useState} from "react";
import {useDropzone} from "react-dropzone";
import qs from "qs";
import axios from "axios";
import {API_URL} from "../constants";
import {Alert, Box, Button, CircularProgress, Link} from "@mui/material";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

function InputUploadLaunchScreenImage(props) {

    const [imageUploading, setImageUploading] = useState(false)
    const [uploadError, setUploadError] = useState(false)
    const [uploadSuccessUrl, setUploadSuccessUrl] = useState(null)
    const [fileName, setFileName] = useState(null)

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: {
            'image/png': [],
        },
        maxFiles: 1,
        onDropRejected: rejectedFiles => {
            setFileName(rejectedFiles[0].file.path)
            setUploadSuccessUrl(null)
            setUploadError(true)
            setImageUploading(false)
        },
        onDropAccepted: acceptedFiles => {
            setImageUploading(true)
            const {oat} = qs.parse(window.location.search.substr(1))

            setFileName(acceptedFiles[0].name)

            const formData = new FormData()
            formData.append('oat', oat)
            formData.append('file', acceptedFiles[0])
            formData.append('type', "launch_screen")
            axios.post(`${API_URL}/upload-image`, formData, {withCredentials: true})
                .then(({ data }) => {
                    setUploadSuccessUrl(data.launch_image_url)
                    setUploadError(false)
                    props.onSuccess()
                })
                .catch(()=>{
                    setUploadSuccessUrl(null)
                    setUploadError(true)
                    props.onUploadError()
                })
                .finally(() => setImageUploading(false));
        }
    });

    return (
        <Box css={css`
              border-radius: 4px;
              box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
              display: inline-block;
              width: 284px;
              min-height: 527px;
              padding:16px
            `}>
            <Box css={css`cursor: pointer;`} {...getRootProps({className: 'dropzone dropzoneLaunchImage'})}>
                <input {...getInputProps()} />
                <ImageOutlinedIcon css={css `font-size: 100; color: black; min-width: 62.5px;min-height:50px;`}/>
                <Box fontSize={16} color={'black'} css={css`margin: 0`}>Drop file here...</Box>
                <Box fontSize={16} color={'black'} css={css`margin: 10px`}>or</Box>
                <Button className='hoverGreen' css={css`background-color: #00A65A;`} variant="contained" startIcon={<CloudUploadOutlinedIcon />}>
                    Choose File
                </Button>
            </Box>
            <Box css={css`padding-top: 24px;`}>
                <Box css={css`font-weight: bold;`}>Launch Screen</Box>
                {uploadError && !imageUploading ? <Alert severity="error">{fileName}</Alert> : '' }
                {uploadError && !imageUploading ? <Box css={css`color: red; font-size: 14px;` }>This file doesn't meet requirements.</Box> : '' }

                {uploadSuccessUrl && !imageUploading ? <Alert severity="success">{fileName} - <Link href={uploadSuccessUrl}>View file</Link></Alert> : '' }

                {imageUploading ? <CircularProgress /> : null}

                <Box className="requirement" css={css`margin-top:20px; font-weight: bold;`}>File Requirements</Box>
                <p className="requirement">1000 x 1600 px (WxH)</p>
                <p className="requirement">Only PNG files</p>
            </Box>
        </Box>
    );

}

export default InputUploadLaunchScreenImage