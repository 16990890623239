const SVGIconFonts = () => {
	return [
		// sans
		'Poppins', 'Oswald',
		// serif
		'Bitter', 'Noto Serif Display',
		// display
		'Fredoka One', 'Ultra',
		// handwriting
		'Kristi',  'Satisfy', 'Homemade Apple', 'Bad Script', 'Neucha', 'Nothing You Could Do', 'Permanent Marker',
		// script
		'Pacifico', 'Lobster',  'Oleo Script', 'Bubblegum Sans',
		'Dancing Script',
		'Bungee',
	]
}

export default SVGIconFonts
